import React from "react";
import "./productList.css";
import ProductCard from "../productCard/ProductCard";

const products = [
  {
    id: 30,
    title: "Facereveal",
    shortDesc: "Reverse search for faces",
    content: "Check out this other images",
    img: require("../../img/facereveal.jpg"),
    // url: 'https://www.fonoxia.com'
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/sv/app/facereveal/id6736849164",    
  }, 
  {
    id: 29,
    title: "Voice-AI",
    shortDesc: "Generate professional speech with AI",
    content: "Check out this other images",
    img: require("../../img/voice-ai.jpg"),
    // url: 'https://www.fonoxia.com'
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/sv/app/voice-ai/id6695725586?platform=iphone",    
  },  
  {
    id: 28,
    title: "Telecare",
    shortDesc: "Doctor and patients app",
    content: "Check out this other images",
    img: require("../../img/telecare.jpg"),
    // url: 'https://www.fonoxia.com'
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 27,
    title: "Smart VoIP service",
    shortDesc: "LLMS and VoIP to automate customer service",
    content: "Check out this other images",
    img: require("../../img/fonoxia.jpg"),
    url: 'https://www.fonoxia.com'
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 26,
    title: "Restaurant App",
    shortDesc: "Restaurant Smart Menu and Chatbot.",
    content: "Check out this other images",
    img: require("../../img/restaurant.jpg"),
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 25,
    title: "Pixelboost",
    shortDesc: "Enhance images using generative AI.",
    content: "Check out this other images",
    img: require("../../img/pixelboost.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/pixelboost/id6473855243",    
  },
  {
    id: 24,
    title: "AI-Vinci",
    shortDesc: "Create and edit images with different AI models.",
    content: "Check out this other images",
    img: require("../../img/aivinci.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/ai-vinci/id6470957084",    
  },
  {
    id: 23,
    title: "ScanChat AI",
    shortDesc: "Scan text and translate with AWS, plus ChatGPT for assistance.",
    content: "Check out this other images",
    img: require("../../img/scanchat.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/scanchat-ai/id6464327115",    
  },
  {
    id: 22,
    title: "ID Checkup",
    shortDesc: "Face comparison with your camera to verify ID ownership.",
    content: "Check out this other images",
    img: require("../../img/idcheckup.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/id-checkup/id6454620982",    
  },
  {
    id: 21,
    title: "Tectrova",
    shortDesc: "Using AWS and React JS. With responsive design.",
    content: "Check out this other images",
    img: require("../../img/tectrova.jpg"),
    // video: 'LyRxRC7gOpM',
    // appstore: "https://apps.apple.com/vn/app/closed-eyes-detector/id1671443609",
    url:'https://main.d1zuo9syrevbgg.amplifyapp.com'
  },
  {
    id: 20,
    title: "Face Compare",
    shortDesc: "Using different AWS services: Rekognition, Secrets and Lambda Functions. ",
    content: "Check out this other images",
    img: require("../../img/compare.jpg"),
    video: '4s-F9MJjl30',
    appstore: "https://apps.apple.com/vn/app/compare-faces/id6450847204",
  },
  {
    id: 19,
    title: "Cam Counter",
    shortDesc: "The first app to count people automatically with AI. No internet connection is needed.",
    content: "Check out this other images",
    img: require("../../img/camcounter.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/camcounter-lite/id6446713414",
  },
  {
    id: 19,
    title: "Closed Eyes",
    shortDesc: "Using AI to detect if you close your eyes. No internet connection is needed.",
    content: "Check out this other images",
    img: require("../../img/closedeyes.jpg"),
    // video: 'LyRxRC7gOpM',
    appstore: "https://apps.apple.com/vn/app/closed-eyes-detector/id1671443609",
  },
  {
    id: 18,
    title: "ChatGPT App",
    shortDesc: "Use ChatGPT to ask questions or generate images",
    content: "Check out this other images",
    img: require("../../img/gpt.jpg"),
    video: "LyRxRC7gOpM",
  },
  {
    id: 17,
    title: "Services App",
    shortDesc:
      "Find providers and customers with chat and payment integration ",
    content: "Check out this other images",
    img: require("../../img/bizzi.jpg"),
    video: "FvRytcd-xKQ",
  },
  {
    id: 16,
    title: "Booking App",
    shortDesc:
      "Book your services using React Native, AWS Amplify and GraphQL ",
    content: "Check out this other images",
    img: require("../../img/booking.jpg"),
    // video: '',
  },
  {
    id: 15,
    title: "MQTT App",
    shortDesc:
      "Turn on and off internet of things switches, keep record in local database ",
    content: "Check out this other images",
    img: require("../../img/mqtt.jpg"),
    video: "XrExurbIjuc",
  },
  {
    id: 14,
    title: "Paperless App",
    shortDesc:
      "With AWS Amplify, QR Scanner, GraphQL and different user groups. ",
    content: "Check out this other images",
    img: require("../../img/paperless.jpg"),
    video: "7fYWvTCBhqY",
  },
  {
    id: 13,
    title: "Auction App",
    shortDesc:
      "With React JS, Firebase and responsive design. Create users, see data in real-time.",
    content: "Check out this other images",
    img: require("../../img/auction.jpg"),
    video: "7KVr5Yqh68U",
  },
  {
    id: 12,
    title: "Chat App",
    shortDesc:
      "With React JS, Sass, and Firebase. Create users, send text and files.",
    content: "Check out this other images",
    img: require("../../img/chat.jpg"),
    video: "_j2CWcA2YHA",
  },
  {
    id: 11,
    title: "Deliveries App",
    shortDesc:
      "With React Native, CSS, GraphQL and Maps. Filter and order users and tracking numbers.",
    content: "Check out this other images",
    img: require("../../img/ups.jpg"),
    video: "CjQG15KIUr4",
  },
  {
    id: 10,
    title: "Survey App",
    shortDesc: "With React Native and CSS.",
    content: "Check out this other images",
    img: require("../../img/survey.jpg"),
    video: "pGIFxNWLlAk",
  },
  {
    id: 0,
    title: "News App",
    shortDesc:
      "With React Native and CSS. View and filter news with topic and date selectors.",
    content: "Check out this other images",
    img: require("../../img/dire.jpg"),
    video: "lycQfq0UDec",
  },
  {
    id: 1,
    title: "Admin Panel",
    shortDesc:
      "With React JS, Sass. Main Dashboard You can add/view/edit products or users.",
    content: "Check out this other images",
    img: require("../../img/adminpanel.jpg"),
  },
  {
    id: 2,
    title: "Live Object Detector",
    shortDesc:
      "With Deeplearning and React Native. You can create reactions for detections in realtime.",
    content: "Coming soon: Read license plates, mask zones and track objects.",
    img: require("../../img/IAD.jpg"),
    video: "zVXdWl2ppeE",
  },
  {
    id: 3,
    title: "Dating App",
    shortDesc: "Using React Native, Firebase, Tailwind and Google maps.",
    content: "This is a Tinder Clone including the same swipping experience.",
    img: require("../../img/tinder.jpg"),
    video: "7jB8zViLxVw",
  },
  {
    id: 4,
    title: "Food Delivery",
    shortDesc: "With React Native, Redux, Tailwind and Google Maps API.",
    content: "With cart and checkout.",
    img: require("../../img/food.jpg"),
    video: "Fnz1c9RR8P4",
  },
  {
    id: 5,
    title: "Rideshare",
    shortDesc: "Using React Native, Tailwind, Google Places API and Maps API.",
    content:
      "This is a clone of Uber. You can calcultate cost based on travel time.",
    img: require("../../img/uber.jpg"),
    video: "fUlVu7-dxBo",
  },
  {
    id: 6,
    title: "Social Media",
    shortDesc: "Using React JS, Redux and CSS. Main Dashboard.",
    content: "You can add/view/edit products or users.",
    img: require("../../img/interface.jpg"),
  },
];

const ProductList = () => {
//   const [showImageModal, setShowImageModal] = useState(false)
//   const [selectedImage, setSelectedImage] = useState('')

//   const handleClickedImage = (image) => {
//     console.log('image: ', image)
//     setSelectedImage(image)
//     setShowImageModal(true)
// }
  return (
    <div className="pl">
      {/* <ImageModal open={showImageModal} setOpen={setShowImageModal} source={selectedImage} /> */}
      <div className="pl-text">
        <h1 className="pl-title">I create solutions</h1>
        <p className="pl-desc">
          This is my personal portfolio, check out some of my previous work.
        </p>
      </div>
      <div className="pl-list">
        {products.map((prod, index) => (
          // <button style={{backgroundColor: 'transparent'}} onClick={() => handleClickedImage(prod.img)} >
            <ProductCard
              title={prod.title}
              shortDesc={prod.shortDesc}
              content={prod.content}
              key={index}
              img={prod.img}
              video={prod.video}
              appstore={prod.appstore}
              url={prod.url}
              // setSelectedImage={setSelectedImage}
            />

          // </button>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
