import React, { useState } from "react";
import './about.css';
import imagen from '../../img/cert.png'
import imagen2 from '../../img/developer.png'
import aboutme from '../../img/aboutme.png'
import { Box, Skeleton, Grid, useTheme, useMediaQuery } from "@mui/material";

const About = () => {
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingImage2, setLoadingImage2] = useState(true)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));


    return (
        <div className="about">
            <div className="about-left">
                <div className="about-card bg"></div>
                <div className="about-card">
                    <img src={aboutme} alt=''className="about-image" />
            </div>
            </div>
            <div className="about-right">
                <h1 className="about-title">About me</h1>
                <p className="about-subtitle">
                    I've been developing software for over 11 years with:
                </p>
                <p className="about-description">
                    Typescript, Javascript, Python, CSS & Sass , Java, SQL, Easylanguage, C++, C# and PHP.
                </p>
                <p className="about-subtitle">
                    Skills:
                </p>
                <p className="about-description">
                    Next JS, React JS, React Native, Artificial Intelligence, UI Design, Webadmin, Tailwind, Web Security, AWS Services, Firebase, GraphQL, Data mining, VoIP and more.
                </p>            
                <p className="about-description">
                    I'm a 5-star developer on Fiverr.com.
                </p>
                <p className="about-description">
                    I'm registered Apple developer.
                </p>
                <p className="about-description">                    
                    I speak English, Spanish, Italian, basic German and a little bit of Portuguese and French.
                </p>
                <p className="about-description">                    
                    I've developed apps that are used by many customers every day.
                </p>
                
            <Grid container sx={{marginTop:-10}} spacing={2} direction={isSmallScreen ? 'column' : 'row'}  wrap="wrap" >
                {/* First Award */}
                <Grid item xs={12} sm={6}>
                    <div className="about-award">
                        {
                            loadingImage && <Skeleton variant="rectangular" width={140} height={140} animation="wave" />
                        }
                        <img src={imagen} className="about-award-image"
                            onLoadStart={() => setLoadingImage(true)}
                            onLoad={() => setLoadingImage(false)}
                            style={loadingImage ? { display: 'none' } : { display: 'block' }}
                        />
                        <div className="about-award-text">
                            <h4 className="about-award-title">Deep Learning Specialization</h4>
                            {/* Rest of your code */}
                        </div>
                    </div>
                </Grid>
                
                {/* Second Award */}
                <Grid item xs={12} sm={6}>
                <div className="about-award">
                    {
                        loadingImage2 && <Skeleton variant="rectangular" width={140} height={140} animation="wave" />
                    }
                    <a href="https://www.credly.com/badges/2c79d74a-76e7-407a-aed3-1ac3358b6b74/public_url" target="_blank" rel="noopener noreferrer">
                        <img src={imagen2} className="about-award-image"
                            onLoadStart={() => setLoadingImage2(true)}
                            onLoad={() => setLoadingImage2(false)}
                            style={loadingImage2 ? { display: 'none' } : { display: 'block' }}
                        />
                    </a>
                    <div className="about-award-text">
                        <h4 className="about-award-title">AWS Certified Developer</h4>
                        {/* Rest of your code */}
                    </div>
                </div>
            </Grid>
            </Grid>
        
            </div>
            
        </div>
    );
}

export default About;